import { onBeforeMount, shallowRef } from 'vue';

export const useParentSearchParams = (cb = () => {}) => {
    const params = shallowRef(null);

    onBeforeMount(() => {
        params.value = new URLSearchParams(window.location.search);

        cb(params.value);
    });

    return params;
};
